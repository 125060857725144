import { InfoWindowF, MarkerF } from "@react-google-maps/api";
import React, { useState } from "react";
import { TbWind } from "react-icons/tb";
import { TiWaves } from "react-icons/ti";
import { WiDirectionUp, WiThermometer } from "react-icons/wi";

const IconText = ({ icon, text }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      alignItems: "center",
      marginBottom: "0.5rem",
    }}
  >
    {icon}
    <span style={{ paddingLeft: "0.5rem" }}>{text}</span>
  </div>
);

export type InfoWindowData = {
  isSmart: boolean;
  forecast_timestamp: Date;
  fetched_timestamp: Date;
  temperature: number | null;
  wave_height: number | null;
  wind_direction: number | null;
  wind_speed: number | null;
};

export const getCardinalDirection = (angle: number) => {
  const directions = [
    "North",
    "North East",
    "East",
    "South East",
    "South",
    "South West",
    "West",
    "North West",
  ];
  return directions[Math.round(angle / 45) % 8];
};

/** Info window content shown to public users. */
const PublicBuoyInfo = ({
  fetched_timestamp,
  forecast_timestamp,
  isSmart,
  temperature,
  wave_height,
  wind_direction,
  wind_speed,
}: InfoWindowData) => (
  <div style={{ width: "240px" }}>
    <p style={{ marginBottom: "1rem", marginTop: "0", fontSize: "15px" }}>
      {isSmart ? "Smart" : "Mooring"} Buoy
    </p>
    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
      <IconText
        icon={<WiThermometer size={20} color="#EE8135" />}
        text={`temp: ${temperature ? `${temperature.toFixed(1)} °C` : "N/A"}`}
      />
      <IconText
        icon={<TiWaves size={20} color="#EE8135" />}
        text={`waves: ${wave_height ? `${wave_height.toFixed(1)} m` : "N/A"}`}
      />
    </div>

    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      <IconText
        icon={<TbWind size={20} color="#EE8135" />}
        text={`wind: ${wind_speed ? `${wind_speed.toFixed(1)} km/h` : "N/A"}`}
      />
      <IconText
        icon={
          <WiDirectionUp
            color="#EE8135"
            size={20}
            style={{ transform: `rotate(${wind_direction - 180}deg)` }}
          />
        }
        text={
          wind_direction ? `${getCardinalDirection(wind_direction)}` : "N/A"
        }
      />
    </div>
    <hr
      style={{
        border: 0,
        height: 0,
        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
      }}
    />
    <RefreshMessage
      fetched_at={fetched_timestamp}
      forecast_for={forecast_timestamp}
    />
  </div>
);

const RefreshMessage = ({ fetched_at, forecast_for }) => (
  <p style={{ fontSize: "10px", marginBottom: "0px" }}>
    Showing data for{" "}
    {new Date(forecast_for).toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    })}{" "}
    as reported at{" "}
    {new Date(fetched_at).toLocaleString([], {
      dateStyle: "short",
      timeStyle: "short",
    })}
    . Always check official forecasts.
  </p>
);

const PublicBuoyMarker: React.FC<{
  id: string;
  lat: number;
  lng: number;
  clusterer: any;
  smart: boolean;
  forecast_timestamp: Date;
  fetched_timestamp: Date;
  temperature: number;
  wind_direction: number;
  wind_speed: number;
  wave_height: number;
}> = (marker) => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <MarkerF
      position={{ lat: marker.lat, lng: marker.lng }}
      onClick={() => setInfoOpen(true)}
      clusterer={marker.clusterer}
      animation={google.maps.Animation.DROP}
      icon={{
        url: `/${marker.smart ? "smart" : "standard"}-marker.png`,
        scale: 1,
        scaledSize: new google.maps.Size(38, 38),
      }}
    >
      {infoOpen && (
        <InfoWindowF
          position={{ lat: marker.lat, lng: marker.lng }}
          onCloseClick={() => setInfoOpen(false)}
        >
          <PublicBuoyInfo
            isSmart={marker.smart}
            forecast_timestamp={marker.forecast_timestamp}
            fetched_timestamp={marker.fetched_timestamp}
            temperature={marker.temperature}
            wave_height={marker.wave_height}
            wind_direction={marker.wind_direction}
            wind_speed={marker.wind_speed}
          />
        </InfoWindowF>
      )}
    </MarkerF>
  );
};

export default PublicBuoyMarker;
