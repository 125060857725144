import {
  GoogleMap,
  MarkerClustererF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { memo, useCallback, useEffect, useState } from "react";
import { Condition } from "../App";
import PublicBuoyMarker from "./PublicBuoyMarker";
import silverMapStyle from "./silverStyle.json";

const center = {
  lat: -2.5674,
  lng: 134.9893,
};

type BuoyMapProps = {
  buoys: Condition[];
};

const PublicBuoyMap = ({ buoys }: BuoyMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: "buoy-map",
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
  });

  const [map, setMap] = useState<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      buoys.map((buoy) => {
        return bounds.extend({
          lat: Number(buoy.latitude),
          lng: Number(buoy.longitude),
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, buoys]);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(undefined);
  }, []);

  return isLoaded ? (
    <GoogleMap
      id="public-buoy-map"
      mapContainerStyle={{
        width: "100%",
        height: "100vh",
      }}
      center={center}
      zoom={2}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        styles: silverMapStyle,
        streetViewControl: false,
        scaleControl: true,
        mapTypeControl: false,
        panControl: false,
        zoomControl: true,
        rotateControl: false,
        fullscreenControl: true,
      }}
    >
      <MarkerClustererF averageCenter gridSize={50}>
        {(clusterer) => (
          <>
            {buoys.map((b) => (
              <PublicBuoyMarker
                key={b.buoy_id}
                id={b.buoy_id}
                lat={b.latitude}
                lng={b.longitude}
                smart={b.smart}
                clusterer={clusterer}
                forecast_timestamp={b.forecast_timestamp}
                fetched_timestamp={b.fetched_timestamp}
                temperature={b.temperature}
                wave_height={b.wave_height}
                wind_direction={b.wind_direction}
                wind_speed={b.wind_speed * 3.6} // Convert m/s to km/h
              />
            ))}
          </>
        )}
      </MarkerClustererF>
    </GoogleMap>
  ) : (
    <div className="spinner-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default memo(PublicBuoyMap);
