import { useEffect, useState } from "react";
import PublicBuoyMap from "./PublicMap/PublicBuoyMap";

export type Condition = {
  buoy_id: string;
  smart: boolean;
  forecast_timestamp: Date;
  latitude: number;
  longitude: number;
  fetched_timestamp: Date;
  temperature: number;
  wind_direction: number;
  wind_speed: number;
  wave_height: number;
};

function App() {
  const [buoys, setBuoys] = useState<Condition[]>([]);

  // Pull current condititions data.
  const getConditionsData = async () => {
    const response = await fetch(
      "https://nemopistorage.blob.core.windows.net/public-map/current-conditions2.json"
    )
      .then((response) => response.json())
      .catch(() => []);

    setBuoys(response);
  };

  useEffect(() => {
    getConditionsData();
  }, []);

  return (
    <div>
      <div className="notification" style={{ margin: "5px" }}>
        This map is currently showing outdated information as we are making
        changes!
      </div>
      <PublicBuoyMap buoys={buoys} />
    </div>
  );
}

export default App;
